import { render, staticRenderFns } from "./DashboardAlerts.vue?vue&type=template&id=1841d343&scoped=true&"
import script from "./DashboardAlerts.vue?vue&type=script&lang=js&"
export * from "./DashboardAlerts.vue?vue&type=script&lang=js&"
import style0 from "./DashboardAlerts.vue?vue&type=style&index=0&id=1841d343&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1841d343",
  null
  
)

export default component.exports